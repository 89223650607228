/* General styles */
body {
  font-family: sans-serif;
  margin: 0;
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: rgb(131, 58, 180);
  background: linear-gradient(
    38deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(217, 76, 160, 1) 34%,
    rgba(252, 176, 69, 1) 100%
  );
}

/* Header styles */
header {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
  color: #fff;
}

h1 {
  font-size: 2em;
  margin-bottom: 0;

  
}

/* Main content styles */
.list_companies {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.day-section {
  text-align: center;
  margin: 10px;
  width: 300px;
  padding: 15px;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 5px;
  /*width: calc(20% - 20px); /* Calculate width based on number of sections */
  box-sizing: border-box;
}
@keyframes float {
  0% {
    box-shadow: 0 10px 5px 0px rgba(0, 0, 0, 0.4); /* Consider simplifying shadow */
    transform: translate3d(0px, 0px, 0);
  }
  33% {
    box-shadow: 0 15px 5px 0px rgba(0, 0, 0, 0.2);
    transform: translate3d(0px, -15px, 0); /* Use translate3d */
  }
  66% {
    box-shadow: 0 10px 5px 0px rgba(0, 0, 0, 0.4);
    transform: translate3d(0px, 15px, 0);
  }
  100% {
    box-shadow: 0 10px 5px 0px rgba(0, 0, 0, 0.4);
    transform: translate3d(0px, 0px, 0);
  }

}

.lyouma {
  transform: translate3d(0px, 0px, 0);
  animation: float 5s ease-in-out infinite;
  will-change: transform; /* Hint browser for optimization */
}

h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  margin-bottom: 5px;
}

.timing{
  display: flex;
  justify-content: space-between;
}

img{
  width: 60%;
  cursor: pointer;
}

.before-after{
  text-align: center;
}

.cardcompany {
  
  
  border-radius: 5px;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
  margin: 0 auto;
  margin-bottom: 10px;
}

.cardcompany:hover {
  transform: scale(1.05);
}

footer{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  flex-direction: column-reverse;
  text-align: center;
}

footer h1 {
  color:#fff;
  font-size: 16px;


}

.biton{
  display: flex;
  align-items: center;
  cursor: pointer;
}

.biton[disabled] { /* Style for when the div is disabled */
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}