@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-family: 'Poppins', sans-serif;
}

.cardcompany {
    display: flex;
    margin-top: 20px;
}

.cardcompany .card {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 128px;
    height: 180px;
    background: #FFF;
    transition: 0.5s;
    box-shadow: 0 35px 80px rgba(0, 0, 0, 0.15);
}

.cardcompany .card .img-box {
    position: absolute;
    top: 20px;
    width: 86px;
    height: 86px;
    border-radius: 1px;
    overflow: hidden;
    transition: 0.5s;
}

.cardcompany .card:hover .img-box {
    top: -20px;
    transform: scale(0.75);
    box-shadow: 0 15px 45px rgba(0, 0, 0, 0.2);
}

.cardcompany .card .img-box img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
}

.cardcompany .card .content {
    position: relative;
    top: 120px;
    height: 30px;
    padding: 0 3px;
    text-align: center;
    transition: 0.5s;
}

.cardcompany .card:hover .content {
    top: 58px;
    height: 250px;
}

.cardcompany .card .content h2 {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--clr);
}

.cardcompany .card .content a {
    position: relative;
    display: flex;
    flex-direction: column;
    top: 15px;
    display: inline-block;
    padding: 12px 25px;
    text-decoration: none;
    background: var(--clr);
    color: #FFF;
    font-weight: 500;
}

.cardcompany p {
    display: none;
}

.cardcompany:hover p {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 6s linear infinite;
}

.cardcompany:hover .ticker-container {

    margin-top: 10px;
    margin-bottom: 10px;

}

@media (max-width: 480px) {
    .cardcompany .card {
        width: 110px;
        border-radius: 15px;
    }

    .cardcompany .card .img-box {
        width: 100px;
        border-radius: 10px;
    }

    .cardcompany .card .content p {
        font-size: 0.8rem;
    }

    .cardcompany .card .content a {
        font-size: 0.9rem;
    }
}

@keyframes marquee {
    0% { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0%); }
}

.ticker {
    border: 1px rgb(0, 0, 0) solid;
    padding: 0 5px;
    font-weight: 700;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.ticker::-moz-focus-inner {
    border: 0;
}


.marquee {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
}
